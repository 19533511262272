<template>
  <div class="footer-style-2">
    <div class="pc-container">
      <p class="pc-container01">服务保障</p>
      <div class="pc-tow-title-container">
        <p>卓能科技-无人值守自动计量首选品牌</p>
        <p>优质服务-24小时全方位一体化服务</p>
      </div>

      <div class="pc-bottom-container">
        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail01.png"
            alt=""
          />
          <p>成熟的解决方案</p>
        </div>

        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail02.png"
            alt=""
          />
          <p>专业的技术团队</p>
        </div>

        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail03.png"
            alt=""
          />
          <p>丰富的项目经验</p>
        </div>

        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail04.png"
            alt=""
          />
          <p>细致的需求沟通</p>
        </div>

        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail05.png"
            alt=""
          />
          <p>精准的建设方案</p>
        </div>

        <div class="pc-bottom-item-container">
          <img
            class="box"
            src="../../../assets/images/detailbot/detail06.png"
            alt=""
          />
          <p>双赢的费用模式</p>
        </div>
      </div>
    </div>

    <CallToAction
      v-if="data.showFooter"
      :style-type="8"
      title="产品了解咨询"
      subtitle="在化工、钢铁、建材、煤焦等领域广泛运用"
      btn-text="立即咨询"
    />
    <CopyrightTwo v-if="data.showCopyright" />
    <ScrollKfDialog v-if="dialog" style="margin-bottom: 120px" />

    <ScrollKf @click.native="showKfDialog" style="margin-bottom: 60px" />
    <ScrollTop />
  </div>
</template>

<script>
import CopyrightTwo from "./CopyrightTwo";
import CallToAction from "../../elements/callToAction/CallToAction";
import ScrollTop from "./ScrollTop";
import ScrollKf from "./ScrollKf";
import ScrollKfDialog from "./ScrollKfDialog";

export default {
  name: "FooterTwo",
  components: {
    CallToAction,
    CopyrightTwo,
    ScrollTop,
    ScrollKf,
    ScrollKfDialog,
  },
  props: {
    data: {
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      
    }
  },

  methods: {
    showKfDialog() {
      console.log(this.dialog);
      if(this.dialog){
        this.dialog = false;
      }else{
        this.dialog = true;
      }
    },
  },
};
</script>

<style>
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .pc-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .pc-container01 {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    color: black;
    font-weight: 400;
  }
  .pc-tow-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 26%;
  }
  .pc-tow-title-container p {
    color: black;
    font-size: 18px;
  }
  .pc-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20%;
    flex-wrap: wrap;
  }
  .pc-bottom-item-container {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    perspective: 800px; /* 设置透视距离，增加3D效果 */
  }
  .pc-bottom-item-container img {
    width: 90px;
    height: 90px;
    margin: auto;
  }
  .pc-bottom-item-container p {
    text-align: center;
    margin-top: 20px;
    color: black;
  }
  .box {
    transition: transform 1s ease-in-out; /* 平滑过渡动画 */
  }
  .pc-bottom-item-container:hover .box {
    transform: rotateY(360deg); /* 沿Y轴旋转360度 */
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  .pc-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .pc-container01 {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    color: black;
    font-weight: 400;
  }
  .pc-tow-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 26%;
    text-align: center;
  }
  .pc-tow-title-container p {
    color: black;
    font-size: 16px;
  }
  .pc-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    flex-wrap: wrap;
  }
  .pc-bottom-item-container {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
  }
  .pc-bottom-item-container img {
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .pc-bottom-item-container p {
    text-align: center;
    margin-top: 20px;
    color: black;
  }
}
</style>
