<template>
  <div
    class="rn-back-top"
    style="
      width: 300px;
      height: 500px;
      background-color: #f6f6f6;
      border-radius: 10px;
    "
    @click="scrollToTop"
  >
    <!-- <Icon name="chevron-up" size="27"/> -->

    <div
      style="
        background-color: #f6f6f6;
        width: 300px;
        height: 500px;
        border-radius: 10px;
        padding: 10px;
      "
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        v-if="!connectTag"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="客服" prop="kfId">
          <el-select v-model="form.kfId" placeholder="请选择客服">
            <el-option
              v-for="item in kfList"
              :key="item.kfId"
              :label="item.kfName"
              :value="item.kfId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="sendMessage('form')">发起客服通话</el-button>
        </el-form-item>
      </el-form>

      <div v-else>
        <span>客服通话中</span>
        <div
          ref="scrollContainer"
          style="
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 10px;
            margin: 5px;
            width: 270px;
            height: 300px;
            overflow-y: auto;
          "
        >
          <div
            v-for="(item, index) in messageList"
            :key="index"
            style="
              text-align: left;
              background-color: aquamarine;
              font-size: 14px;
              line-height: 1;
              padding: 10px;
            "
          >
            {{ item.type == "kf" ? "客服：" : form.name + "：" }}{{ item.msg }}
          </div>
        </div>
        <el-input v-model="msg" type="textarea" :rows="2"></el-input>
        <el-button @click="sendMsg" size="mini">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import { getKfList } from "../../../api/index";

export default {
  name: "ScrollKfDialog",
  components: { Icon },
  data() {
    return {
      visible: false,
      dialogVisible: true,
      form: {
        name: null,
        phone: null,
        kfId: null,
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          ,
          {
            pattern: /^1(3|4|5|7|8|9)\d{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        kfId: [{ required: true, message: "请选择客服", trigger: "change" }],
      },
      socket: null,

      connectTag: false,
      msg: "",
      messageList: [],
      kfList: [],
      heartbeatInterval: null,
    };
  },
  watch: {
    messageList: {
      handler() {
        this.scrollToBottom();
      },
      deep: true
    }
  },
  methods: {

    startHeartbeat() {
      // 每 10 秒发送一次心跳
      this.heartbeatInterval = setInterval(() => {
        this.sendHeartbeat();
      }, 10000);
    },
    sendHeartbeat() {
      // 发送心跳消息
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send('heartbeat' );
        console.log('Heartbeat sent');
      } else {
        console.warn('WebSocket is not open, cannot send heartbeat');
      }
    },
    stopHeartbeat() {
      // 清除心跳定时器
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    scrollToTop() {
      // window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth'
      // });
      this.dialogVisible = true;
      console.log("scroll");
    },

    sendMessage(formName) {
      const that = this;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");

          if ("WebSocket" in window && that.socket == null) {
            // socket = new WebSocket('ws://http://47.98.236.63:8085/ws/alarmReport/{deptId}/{userId}');
            that.socket = new WebSocket(
              // "wss://47.98.236.63:8085/ws/alarmReport/" + deptId + "/" + userId
              "ws://www.topowersx.com/ws/chat/" +
                "visitor" +
                "/" +
                that.form.phone +
                "/" +
                that.form.kfId
            );
          } else {
            // 对不支持 WebSocket 的浏览器进行处理
          }

          // 建立连接成功后的回调
          that.socket.onopen = function (event) {
            console.log("Connected to WebSocket server.");

            that.connectTag = true;

            that.startHeartbeat();
          };

          // 接收服务端推送的消息
          that.socket.onmessage = function (event) {
            // const data = JSON.parse(event.data);
            // 在这里处理接收到的数据，例如更新 Vue 组件的状态
            // this.$store.commit("updateRealtimeData", data);
            console.log("Connected to WebSocket server. success: ", event);

            // if (event.data == "客服连接已建立") {
              if(event.data != 'heartbeat'){
                that.messageList.push({ type: "kf", msg: event.data });

              }
            // } else {
            // console.log(event.data);
            // }
          };

          // 关闭连接时的回调
          that.socket.onclose = function (event) {
            console.error("WebSocket connection closed.");
            that.socket = null;
            that.stopHeartbeat();
          };

          // 错误处理
          that.socket.onerror = function (error) {
            console.error("WebSocket error: ", error);
          };
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    sendMsg() {
      if (this.msg.trim() != "") {
        // this.socket.send(
        //   JSON.stringify({
        //     msg: this.msg,
        //     name: this.form.name,
        //     phone: this.form.phone,
        //   })
        // );

        this.socket.send(this.msg);

        this.messageList.push({ type: "visitor", msg: this.msg });
        this.msg = "";
      }
    },
    toggleVisible() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        this.visible = true;
      } else if (scrolled <= 300) {
        this.visible = false;
      }
    },


  },
  created() {
    window.addEventListener("scroll", this.toggleVisible);

    getKfList().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        this.kfList.push({
          kfId: res.data[i].kfCode,
          kfName: "客服" + res.data[i].kfCode,
        });
      }
    });
  },
  mounted() {
    this.toggleVisible();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleVisible);

    console.log("close");

    if (this.socket) {
      this.socket.close();
      this.kfList = [];
    }
  },
};
</script>
